.home {
    display: flex;
    border-radius: 20px;
    width:100%
}

.homeContainer {
    flex: 6;
    font-family: 'Ubuntu-Regular' !important;
    text-align: left;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
}
.card {
    max-width: 300px;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid transparent;
  }
  
  .card a {
    text-decoration: none
  }
  
  .content {
    padding: 1.1rem;
  }
  
  .image,
  .image img {
    object-fit: cover;
    width: 100%;
    height: 200px;
    background-color: rgb(239, 205, 255);
  }
  
  .title {
    color: #111827;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
  }
  
  .desc {
    margin-top: 0.5rem;
    color: #6B7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  
  .action {
    display: inline-flex;
    margin-top: 1rem;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    align-items: center;
    gap: 0.25rem;
    background-color: #2563EB;
    padding: 4px 8px;
    border-radius: 4px;
    justify-content: flex-end;
  }
  
  .action span {
    transition: .3s ease;
  }
  
  .action:hover span {
    transform: translateX(4px);
  }
  .blog__content{
display: grid;
grid-template-columns: repeat(3,1fr);
row-gap: 20px;
  }
  .imgcolor,
  .img,
  .uploadimges{
      width: 100%;
      height: 100%;
      margin-left: 5px;
      margin-bottom: 20px;
  }
  .fileImg {
      display: none;
  }
  .imggg{
      height: 100%;
      width: 100%;
  }
  .homeContainer__link{
    color:  #fff !important;
  }
  .delete__blog{
    text-align: right;
    margin: 10px 0;
  }