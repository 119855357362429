.home {
    display: flex;
    border-radius: 20px;
    width:100%
}

.homeContainer {
    flex: 6;
    font-family: 'Ubuntu-Regular' !important;
    text-align: left;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
}
.new__title {
    color: #474747;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding-left: 5px;
    margin-bottom: 40px;
}
body[dir='rtl'] .new__title{
    text-align: right;
}
.new__para {
    color: #979C9E;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}
.info__title{
    color: #000;
    font-family: 'Ubuntu-Regular' !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
}
.info__para{
    color: var(--subtitle-color, #979C9E);
    font-family: 'Ubuntu-Regular' !important;
            font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
}
body[dir='rtl'] .info__title {
    text-align: right;
}
body[dir='rtl'] .info__para {
    text-align: right;
}
.newcreate__title{
    color: #000;
    font-family: 'Ubuntu-Regular' !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 40px;
}
body[dir='rtl'] .newcreate__title {
    text-align: right;
}
.pickmap,
.pickdate{
    color: #000;
    font-family: 'Ubuntu-Regular' !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
}
body[dir='rtl'] .pickmap {
    text-align: right;
}
body[dir='rtl'] .pickdate {
    text-align: right;
}
.pickdate{
    margin-top: 30px;
}
.group{
    display: flex;
        align-items: center;
        border-radius: 15px;
        padding: 5px 10px;
            background-color: var(--second-color, #EEEEEC);
}
.group svg{
    margin-right: 10px;
}

.gruoinput{
    background-color: transparent !important;
    border: none !important;
        color: #777;
        outline: none;
        width: 100%;
        border: 0;
        padding: 0.5em 10px 0.5em 0;
        font-size: 15px;
}
.gruoinput:focus{
    box-shadow: none !important;
    outline: none !important;
}
.gruoinput::placeholder{
    color: var(--subtitle-color, #979C9E);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-family: 'Ubuntu-Regular' !important;
}
.groupline{
    position: relative;
}
.line{
    position: absolute;
    top:15px;
    left: 20px;
}
body[dir='rtl'] .line {
right: 31px;
}
.mapphoto{
    text-align: right !important;
}
body[dir='rtl'] .mapphoto {
    text-align: left !important;
}
.additems{
    margin-top: 40px;
}
.info{
    border-radius: 15px;
    margin-bottom: 20px;
        background-color: var(--second-color, #EEEEEC);
}
.info__delete{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
}
.field{
    padding: 0 25px;
}
.center{
    display: flex;
    align-items: center !important;
}
.select{
    height: 38px !important;
    margin-top: 17px;
}
.size{
    display: flex;
    column-gap: 10px;
        align-items: center !important;
}
.selectclass{
    width: 100% !important;
    height: 38px !important;
    margin-top: 0px;
}
.label{
    color: #000;
    font-family: 'Ubuntu-Regular' !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
}
body[dir='rtl'] .field{
    text-align: right !important;
    justify-content: right;
    direction: rtl;
}
.total{
    border-radius: 0px 0px 15px 15px;
        background-color: #FF8201;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
}
.total h3{
    color: #FFF;
        text-align: center;
        font-family: 'Ubuntu-Regular' !important;       font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
}
.item__index{
    color: #FFF;
    font-family: 'Ubuntu-Regular' !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 15px;
            background-color: #9b9b9b;
            padding: 5px 15px;
}
.info__input{
    background-color: var(--222222, #F8F8F7) !important;
    border: none !important;
}
.info__input:focus{
    box-shadow: none !important;
}
.removebtn{
    background-color: rgb(170, 5, 5);
    color: #FFF;
    border: 1px solid rgb(170, 5, 5);
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    padding: 5px 15px;
    font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-family: 'Ubuntu-Regular' !important;
}
.addbtn{
    background-color: #9b9b9b;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 5px 30px ;
    margin-right: 20px;
    color: #FFF;
    font-family: 'Ubuntu-Regular' !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: none !important;
}
body[dir='rtl'] .addbtn{
margin-left: 10px;
}
.collect__body{
    display: flex;
    justify-content: right;
    margin-bottom: 30px;
}
.collect{
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    background-color: #1C1A19;
    padding: 9px 30px;
        color: #FFF;
        font-family: 'Ubuntu-Regular' !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border: none !important;
            margin-left: 10px;
}
@media (min-width:0px) and (max-width:600px) {
    .home{
        display: flex;
        flex-direction: column;
    }
    .homeContainer{
        padding-left: 10px;
        padding-right: 10px;
    }
    .mapphoto img{
        width: 100% !important;
    }
    .one{
        display: flex;
        flex-direction: column;
    }
    .size{
        margin-top: 10px;
    }
}
@media (min-width:900px) and (max-width:1400px) {
    .label{
        font-size: 10px;
        font-weight: 400 !important;
        
    }
}
.date__body{
    display: flex;
    column-gap: 20px;
}
.date__body div{
width: 100% !important;
}
.imgcolor,
.img,
.uploadimges{
    width: 50%;
    height: 50%;
    margin-left: 5px;
    margin-bottom: 20px;
}
.fileImg {
    display: none;
}
.imggg{
    height: 100%;
    width: 100%;
}
/******/
.delete__btn{
    display: flex !important;
    justify-content: right !important;
    align-items: right !important;
    text-align: right !important;
   padding-bottom: 10px;
   margin-right: 10px;
}