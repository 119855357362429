.home {
    display: flex;
    border-radius: 20px;
    width:100%
}

.homeContainer {
    flex: 6;
    font-family: 'Ubuntu-Regular' !important;
    text-align: left;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
}
.base__body{
    box-shadow: rgba(0,0,0,0.05) 0px 6px 24px 0px, rgba(0,0,0,0.08) 0px 0px 0px 1px;
    padding: 15px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-bottom: 15px;
}
.select{
    padding: 8px;
        display: block;
        width: 100%;
        transition: border-color 100ms ease;
        text-align: var(--input-text-align);
        color: #000;
        border: 1px solid #ced4da;
        background-color: #fff;
        border-radius: 5px;
    
    }
    .title,
.description{
    margin-top: 15px;
}
.sub__btn{
    margin-top: 10px;
    display: flex;
    justify-content: right;
}