.m-8fdc1311{
  font-family: 'Ubuntu-Regular' !important;
    font-size: 20px;
}
.carousel.carousel-slider{
    margin: auto !important;
    width: 400px !important;
    height: 400px !important;
    
}
.css-exd1zr {
display: none !important;
}
.css-15wwp11-MuiTableHead-root { background-color: #3f72e7 !important; border-radius: 5px !important; -webkit-border-radius: 5px !important; -moz-border-radius: 5px !important; -ms-border-radius: 5px !important; -o-border-radius: 5px !important;
}
.css-1ndpvdd-MuiTableCell-root{
    color: #fff !important;
    font-family: Ubuntu-Regular !important;
font-size: 17px !important;
}
.searchbar {
    font-size: 14px;
    font-family: "Calistoga", sans-serif;
    color: #202124;
    display: flex;
    z-index: 3;
    height: 44px;
    background: white;
    border: 1px solid #dfe1e5;
    box-shadow: none;
    border-radius: 8px;
width: 50%;
  }
  
  .searchbar:hover {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border-color: rgba(223,225,229,0);
  }
  
  .searchbar-wrapper {
    flex: 1;
    display: flex;
    padding: 5px 8px 0 14px;
  }
  
  .searchbar-left {
    font-size: 14px;
    font-family: "Calistoga", sans-serif;
    color: #202124;
    display: flex;
    align-items: center;
    padding-right: 13px;
    margin-top: -5px;
  }
  
  .search-icon-wrapper {
    margin: auto;
  }
  
  .search-icon {
    margin-top: 3px;
    color: #9aa0a6;
    height: 20px;
    line-height: 20px;
    width: 20px;
  }
  
  .searchbar-icon {
    display: inline-block;
    fill: currentColor;
    height: 24px;
    line-height: 24px;
    position: relative;
    width: 24px;
  }
  
  .searchbar-center {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }
  
  .searchbar-input-spacer {
    color: transparent;
    flex: 100%;
    white-space: pre;
    height: 34px;
    font-size: 16px;
  }
  
  .searchbar-input {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .87);
    word-wrap: break-word;
    outline: none;
    display: flex;
    flex: 100%;
    margin-top: -37px;
    height: 34px;
    font-size: 16px;
    max-width: 100%;
    width: 100%;
  }
  
  .searchbar-right {
    display: flex;
    flex: 0 0 auto;
    margin-top: -5px;
    align-items: stretch;
    flex-direction: row
  }
  
  .searchbar-clear-icon {
    margin-right: 12px
  }
  /******/
  @keyframes square-animation {
    0% {
     left: 0;
     top: 0;
    }
   
    10.5% {
     left: 0;
     top: 0;
    }
   
    12.5% {
     left: 32px;
     top: 0;
    }
   
    23% {
     left: 32px;
     top: 0;
    }
   
    25% {
     left: 64px;
     top: 0;
    }
   
    35.5% {
     left: 64px;
     top: 0;
    }
   
    37.5% {
     left: 64px;
     top: 32px;
    }
   
    48% {
     left: 64px;
     top: 32px;
    }
   
    50% {
     left: 32px;
     top: 32px;
    }
   
    60.5% {
     left: 32px;
     top: 32px;
    }
   
    62.5% {
     left: 32px;
     top: 64px;
    }
   
    73% {
     left: 32px;
     top: 64px;
    }
   
    75% {
     left: 0;
     top: 64px;
    }
   
    85.5% {
     left: 0;
     top: 64px;
    }
   
    87.5% {
     left: 0;
     top: 32px;
    }
   
    98% {
     left: 0;
     top: 32px;
    }
   
    100% {
     left: 0;
     top: 0;
    }
   }
   
   .loader {
    position: relative;
    width: 96px;
    height: 96px;
    transform: rotate(45deg);
   }
   
   .loader-square {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    margin: 2px;
    border-radius: 0px;
    background: #000;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    animation: square-animation 10s ease-in-out infinite both;
   }
   
   .loader-square:nth-of-type(0) {
    animation-delay: 0s;
   }
   
   .loader-square:nth-of-type(1) {
    animation-delay: -1.4285714286s;
   }
   
   .loader-square:nth-of-type(2) {
    animation-delay: -2.8571428571s;
   }
   
   .loader-square:nth-of-type(3) {
    animation-delay: -4.2857142857s;
   }
   
   .loader-square:nth-of-type(4) {
    animation-delay: -5.7142857143s;
   }
   
   .loader-square:nth-of-type(5) {
    animation-delay: -7.1428571429s;
   }
   
   .loader-square:nth-of-type(6) {
    animation-delay: -8.5714285714s;
   }
   
   .loader-square:nth-of-type(7) {
    animation-delay: -10s;
   }
   .loaduser{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   }
   .btn__location{
display: flex;
align-items: flex-end;
justify-content: right;

   }
  /*******************/

.newboard {
width: 100% !important;
}
.addBoard{
    color: #fff !important;
}
.neww{
  color:  #fff !important;
}
.row__chats{
  column-gap: 30px !important;
}
.form__send{
  border-top: 1px solid #dfe1e5;

}
.form__send textarea.form-control{
  border: none !important;
}
.form__send textarea.form-control:focus{
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.form__send__row{
display: flex;
align-items: center;

column-gap: 2px;
}
.seq .m_cf365364{
  width: 100%;
}