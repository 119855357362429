.home {
    display: flex;
    border-radius: 20px;
}

.homeContainer {
    flex: 6;
    font-family: 'Ubuntu-Regular' !important;
    text-align: left;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #f5f6fa;

}

.new__title {
    color: #474747;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding-left: 5px;
    margin-bottom: 50px;
}

body[dir='rtl'] .new__title {
    text-align: right;
}


.new__para {
    color: #979C9E;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.msg__side {
    border-radius: 8px;
    background-color: #fff;
    padding: 10px 0px;
    width: 400px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.inputBox_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 50px;

    overflow: hidden;
    margin-right: 10px;
    border-bottom: 1px solid #eee;
}

.search_icon {
    height: 1em;
    padding: 0 0.5em 0 0.8em;
    fill: #333;
}

.inputBox {
    background-color: transparent;
    color: #777;
    outline: none;
    width: 100%;
    border: 0;
    padding: 0.5em 1.5em 0.5em 0;
    font-size: 15px;
}

::placeholder {
    color: var(--color-place);
}

.pin__msg {
    color: var(--black, #1C1A19);
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    opacity: 0.5;
    font-family: 'Ubuntu-Regular' !important;
    padding: 24px 22px 10px;
}

body[dir='rtl'] .pin__msg {
    text-align: right;
}

.chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    margin-bottom: 20px;
    background-color: #f6f9fe;
    border-left: 3px solid #2771da;
/*     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
 */}

.chat1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    margin-bottom: 20px;
}

.user__chat {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 15px 22px 2px 22px;
    cursor: pointer;
}

.user__chat img {
    width: 70px;
}

.user__chat h3 {
    color: var(--black, #1C1A19);
    opacity: 0.5;
    font-family: 'Ubuntu-Regular' !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

body[dir='rtl'] .user__chat h3 {
    text-align: right;
}

.user__chat p {
    color: var(--black, #1C1A19);
    font-family: 'Ubuntu-Regular' !important;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 230px;
}
.send__body div,
.arcsend__body div{
    text-align: right;
}

body[dir='rtl'] .user__chat p {
    text-align: right;
}

.name p {
    color: var(--black, #1C1A19);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: 'Ubuntu-Regular' !important;
    padding-bottom: 0;
    margin-bottom: 0;
}

.num {
    position: relative;
    text-align: right;
}

.num img {
    width: 15px;
}

.num p {
    position: absolute;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: 'Ubuntu-Regular' !important;
    top: 6px;
    left: 26px;
}

/*--------------------------------*/
.msg__content {
    border-radius: 8px;
    background: #fff;
    padding: 10px;
    padding-bottom: 70px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

}

.online {
    position: relative;
}

.online__img {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50px;
    left: 29px;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 10px;
    margin-bottom: 50px;
}

.action__body img {
    margin-right: 10px;
}

.received {
    margin-bottom: 10px;
}

.received__body,
.arcreceived__body {
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 0px 22px;
}

.received__body p,
.arcreceived__body p {
    border-radius: 10px 10px 10px 0px;
    background-color: #ebf3fe;
    padding: 10px 25px 10px 15px;
    color: var(--black, #1C1A19);
    font-family: 'Ubuntu-Regular' !important;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    -webkit-border-radius: 10px 10px 10px 0px;
    -moz-border-radius: 10px 10px 10px 0px;
    -ms-border-radius: 10px 10px 10px 0px;
    -o-border-radius: 10px 10px 10px 0px;
}
.arcreceived__body p{
    background-color: #8e939b;
    color: #fff;
}
body[dir='rtl'] .received__body p {
    border-radius: 40px 0 0 40px;
    -webkit-border-radius: 40px 0 0 40px;
    -moz-border-radius: 40px 0 0 40px;
    -ms-border-radius: 40px 0 0 40px;
    -o-border-radius: 40px 0 0 40px;
}

.seen {
    text-align: left;

    margin-top: 10px;

}
.seen1{

    
    margin-top: 10px;
}
body[dir='rtl'] .seen {
    text-align: left;
}

.seen,
.seen1 {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: 'Ubuntu-Regular' !important;

}

.send {
    margin-top: 30px;
    padding-right: 25px;
}

body[dir='rtl'] .send {
    direction: rtl;
}

.send__body,
.arcsend__body {
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 0px 22px;
    justify-content: right;
}

body[dir='rtl'] .send__body {
    justify-content: left !important;
}

.send__body img,
.arcsend__body img {
    width: 46px;
    height: 46px;
}

.send__body p ,
.arcsend__body p{
    border-radius: 10px 10px 0px 10px;
    background-color: #f7f7f7;
    padding: 10px 25px 10px 15px;
    color: var(--black, #1C1A19);
    font-family: 'Ubuntu-Regular' !important;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    -webkit-border-radius: 10px 10px 0px 10px;
    -moz-border-radius: 10px 10px 0px 10px;
    -ms-border-radius: 10px 10px 0px 10px;
    -o-border-radius: 10px 10px 0px 10px;
}
.arcsend__body p{
    background-color: #2c2c2c !important;
    color: #fff;
}
.send__imge{
    border-radius: 10px 10px 0px 10px;
    background-color: #f7f7f7;
    padding: 10px 25px 10px 15px;
}
.arcsend__imge{
    border-radius: 10px 10px 0px 10px;
    background-color: #2c2c2c;
    padding: 10px 25px 10px 15px;
}
body[dir='rtl'] .send__body p {
    border-radius: 0px 40px 40px 0px;
    -webkit-border-radius: 0px 40px 40px 0px;
    -moz-border-radius: 0px 40px 40px 0px;
    -ms-border-radius: 0px 40px 40px 0px;
    -o-border-radius: 0px 40px 40px 0px;
}

.icons {
    margin-left: 22px;
}

.icons svg {
    margin-right: 10px;
    width: 16px;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: right;
    column-gap: 30px;
    width: 100px;
}

.cancel__btn {
    border-radius: 8px;
    border: 1px solid var(--main-color, #016fff);
    background-color: #FFF;
    display: flex;
    width: 123px;
    height: 38px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: var(--main-color, #016fff);
    text-align: center;
    font-family: 'Ubuntu-Regular' !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.send__btn {
    border-radius: 8px;
    background-color: var(--main-color, #016fff);
    display: flex;
    width: 150px !important;
    height: 38px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #fff;
    text-align: center;
    font-family: 'Ubuntu-Regular' !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: 1px solid #016fff;

}

@media (min-width:0px) and (max-width:600px) {
    .home {
        display: flex;
        flex-direction: column;
    }

    .homeContainer {
        padding-left: 10px;
        width: 100%;
        padding-right: 10px;
    }

    .msg__side,
    .inputBox_container {
        width: 100%;
    }

    .msg__side {
        margin-bottom: 15px;
    }
}

@media (min-width:600px) and (max-width:900px) {
    .msg__side {
        width: 100%;
        margin-bottom: 20px;
    }

    .inputBox_container {
        width: 100%;
    }
}

@media (min-width:1200px) and (max-width:1400px) {

    .msg__side,
    .inputBox_container {
        width: 100%;
    }

    .user__chat h3 {
        font-size: 12px;
    }

    .user__chat p {
        font-size: 10px;
    }

    .user__chat img {
        width: 50px;
    }

    .name p {
        font-size: 7px;
    }

    .num p {
        left: 17px;
        font-size: 10px;
    }

    .online__img {
        top: 29px;
        left: 18px;
    }
}

@media (min-width:900px) and (max-width:1200px) {

    .msg__side,
    .inputBox_container {
        width: 100%;
    }
}

.user__chat img {
    width: 50px;
}

.user {
    width: 40px;
}
.img__msg{
    width: 100%;
    height:100% ;
}
.prog div {
    width: 50% !important;
    margin-top: 20px;
}
.end{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
