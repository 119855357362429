.home {
    display: flex;
    border-radius: 20px;
    width:100%
}

.homeContainer {
    flex: 6;
    font-family: 'Ubuntu-Regular' !important;
    text-align: left;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
}
.color__body{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    gap: 20px;
    cursor: pointer;
}
.color{
    width: 100%;
    height: 100px;
    margin-bottom: 8px;
}
.color_style{
    box-shadow: rgba(0,0,0,0.05) 0px 6px 24px 0px, rgba(0,0,0,0.08) 0px 0px 0px 1px;
padding: 10px;
border-radius: 3px;
text-align: center;
}
.filter__body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.paginate__body{
    display: flex;
    justify-content: right;
    margin-top: 15px;
    column-gap: 5px;
}
.modal__body{
    margin: auto !important;
}