.home {
    display: flex;
    border-radius: 20px;
    width:100%
}

.homeContainer {
    flex: 6;
    font-family: 'Ubuntu-Regular' !important;
    text-align: left;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
}
.select{
    padding: 8px;
        display: block;
        width: 100%;
        transition: border-color 100ms ease;
        text-align: var(--input-text-align);
        color: #000;
        border: 1px solid #ced4da;
        background-color: #fff;
        border-radius: 5px;
    
    }
    .sub__title{
        text-align: center;
    }
    .sub__btn{
        margin-top: 10px;
        display: flex;
        justify-content: right;
    }