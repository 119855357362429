
    .sidebar {
        background-color: #EEEEEC;
        transition: all .5s ease-in-out;
      
        padding-top: 20px;
        width: 270px;
        font-family: 'Ubuntu-Regular' !important;
        /* min-height: 1000px; */
        
       
    }
body[dir='rtl'] .sidebar{
    margin-right: 40px;
}
    .sidebar:hover .span {
        transition: all .5s ease-in-out;
    }

    
    .top {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px !important;
    }

    .logo {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-top: 20px
    }

    hr {
        height: 0;
        border: 0.5px solid rgb(230, 227, 227);
    }

    .center {
        display: flex;
    }

    ul {
        list-style: none;
        margin: auto;
        padding: 0px !important;
    }

    .title {
        font-size: 10px;
        font-weight: bold;
        color: #fffefe !important;
        margin-top: 15px;
        margin-bottom: 5px;
        display: none;
    }

    .li,
    .li1,
    .li2 {
        display: flex;
        align-items: center !important;
        cursor: pointer;
        margin: 15px 0px 0px 20px !important;
        color: #000;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 17px !important;
        padding: 20px 20px !important;
        line-height: 1.625rem !important;
        position: relative;
        z-index: 90 !important;
    }

    .li:hover {
        width:250px;
        border-radius: 10px 0 0 10px;
        background-color: #fff;
        font-size: 17px !important;
        }
    .active {
        display: flex;
        align-items: center !important;
        cursor: pointer;
        margin: 5px 20px 0px 20px !important;
        color: #000;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 17px !important;
        padding: 20px 20px !important;
        line-height: 1.625rem !important;
        position: relative;
        background-color: #fff;
        z-index: 90 !important;
        width:250px;
        border-radius: 10px 0 0 10px;
        
    }

    .active .span,
    .active .icon {
        color: #000 !important;
        fill: #000 !important;
        font-size: 15px;
        font-weight: 600;
    }

    .icon {
        font-size: 23px;
        fill:#000
    }

    body[dir="rtl"] .span {
        margin-right: 5px;
        
    }

    .span {
        font-size: 15px;
        font-weight: 600;
        color: #000 !important;
        margin-left: 10px;
        opacity: 1;
        transition: all .5s ease-in-out;
        width: 10;
        text-align: center;
        border: none;
        background-color: transparent;
        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        
    }

    .li:hover .icon,
    .li:hover span,
    .li1:hover span {
        color: #000 !important;
        fill: #000 !important;
        font-size: 15px;
        font-weight: 600;
    }

    .children {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bottom {
        display: flex;
        flex-direction: column;
        margin: 10px;
        justify-content: flex-end;
        margin-top: 200px;
    }

    .bottom ul {
        margin: auto;
    }

    .colorOption {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid #fff;
        cursor: pointer;
        margin: 5px;
    }

    .colorOption:nth-child(1) {
        background-color: whitesmoke;
    }

    .colorOption:nth-child(2) {
        background-color: #333;
    }

    .side-bar__logo {
        width: 100px;
        height: auto;
        margin-top: 10px;
        padding: 5px;

    }

    .logo__title {
        text-align: center;
        color: #0194FE !important;
        font-size: 20px;
        font-weight: bold;
    }
    .contactus{
        padding-left: 10px;
        color: #FF8201;
        font-family: Ubuntu-Regular !important;
    }
         body[dir="rtl"] .contactus {
        padding-right: 10px;
    
         }
         .banner__ico{
            width: 24px;
            height: 24px;
         }
@media (min-width:0px) and (max-width:600px) {
    .sidebar{
        display: none !important;
    }
}
@media (min-width:600px) and (max-width:900px) {
.sidebar {
        display: none !important;
    }
}
@media (min-width:900px) and (max-width:1200px) {
    .sidebar{
    display: none;
    }
}
